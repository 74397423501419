<template>
  <div>
    <GlobalForm
      class="global-form"
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="100"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item class="el_from" label="门店商家" slot="operationSelect" style="margin:0">
        <el-select
          v-model="merchantId"
          placeholder="请选择商家名称"
          clearable
          filterable
          @change="handleChange"
        >
          <el-option
            v-for="item in shanpsSelectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </GlobalForm>
  </div>
</template>

<script >
import moment from 'moment'
import _api from "@/utils/request";
export default {
  components: {},
  props: {
    isManager: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iid: null,
      initData: {
        entryTime: [moment().day(moment().day()).format('YYYY-MM-DD 00:00:00'),moment().format('YYYY-MM-DD 23:59:59')]
      },
      merchantId: [],
      shanpsSelectList: [],
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      formItemList: [
        { slotName: "operationSelect" },
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "时间",
          placeholder: "请选择时间",
          valueFormat: "timestamp",
          clearable: false,
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
      ],
    };
  },
  watch: {
    isManager() {
      this.handleConfirm(this.initData)
    }
  },
  created() {
  //   let startTime = moment().day(moment().day() - 6).format('YYYY-MM-DD 00:00:00') // 当前时间往前推15天的时间
  //   let endTime = moment().format('YYYY-MM-DD 23:59:59')
  //   this.entryTime = [startTime,endTime]
    // const params = {
    //   pageNum: 0,
    //   pageSize: 999,
    // };
    this.orderNo = this.$route.query.orderNo || "";
    _api.getSelectList({onlinePay : true}).then((res) => {
      if (res.code === 1) {
        this.shanpsSelectList = res.data;
      }
    });
    this.handleConfirm(this.initData)
  },
  mounted() {},
  methods: {
    handleConfirm(data,cd) {
      let SeachParams = {};
      if (data) {
     let startTime = ""
            let endTime = ""
        if (data.entryTime === null) {
            (startTime = ''), (endTime = '')
        } else {
            (startTime = moment(data.entryTime[0]).format("x")),
            (endTime = moment(data.entryTime[1]).format("x"))
        }

        SeachParams = {
          startTime : startTime,
          endTime : endTime,
          companyIds: this.iid || "",
          isManager: this.isManager,
          pageNum: 1,
          pageSize: 10,
        };
      }
      SeachParams. disuseLoding= () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    handleChange(e) {
      this.iid = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.global-form {
  /deep/.el-input__inner {
    width: 160px;
  }
}
</style>
